import React from "react"
export default function Navbar(){
    
    return (
        <nav className = "nav--container">
        <div className="flex--container">
         <i className="fa fa-globe" style= {{fontSize:"16px"}}></i>
         <h2>My travel journal</h2>
        </div>
        
        </nav>
    )
}